import AccountPageMenu from '@/components/AccountPageMenu/AccountPageMenu';
import MainContent from '@/components/MainContent';
import MainFooter from '@/components/MainFooter';
import MainHeader from '@/components/MainHeader';
import MoreHeader from '@/components/MoreHeader';
import Navbar from '@/components/Navbar';
import StickyBars from '@/components/StickyBars';
import type { VNode } from 'vue';

export default defineNuxtComponent({
  name: 'MoreLayout',
  setup() {
    useHead({
      htmlAttrs: {
        'data-layout': 'more',
      },
    });
    const route = useRoute();
    return { route };
  },
  render(): VNode {
    return (
      <div class="flex min-h-screen flex-col print:min-h-full">
        <MainHeader>
          <Navbar />
        </MainHeader>
        <StickyBars />
        <div class="max-w-screen-2xl flex-1 lg:flex">
          <AccountPageMenu class="lg:border-r-rb-light-gray lg:border-r-1 hidden basis-1/3 max-lg:p-4 lg:block lg:p-8" />
          <div class="flex flex-col lg:basis-2/3">
            {this.route.meta.titleKey && (
              <MoreHeader to={String(this.route.meta.parent)}>
                {this.$t(String(this.route.meta.titleKey))}
              </MoreHeader>
            )}
            <MainContent class="w-full">{this.$slots.default?.()}</MainContent>
          </div>
        </div>
        <MainFooter />
      </div>
    );
  },
});
